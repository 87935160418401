:root {
  --tooltip-width: 600px;
}

.tooltip {
  width: 360px;
  border-radius: 20px;
  padding: 24px;
}

.tooltip-dark {
  width: 360px;
  border-radius: 20px;
  padding: 24px;
  background: #282828;
}

.introjs-tooltipReferenceLayer {
  visibility: visible !important;
}

.tooltip {
  width: 360px;
  border-radius: 20px;
  padding: 24px;
}

.tooltip-one-step {
  width: var(--tooltip-width);
  max-width: 600px;
  border-radius: 20px;
  padding: 24px;
}

.tooltip-one-step-dark {
  width: var(--tooltip-width);
  max-width: 600px;
  border-radius: 20px;
  padding: 24px;
}

.tooltip-dark {
  width: 360px;
  border-radius: 20px;
  padding: 24px;
  background: #282828;
}

.introjs-skipbutton {
  line-height: unset;
  color: black;
  font-size: 40px;
  font-weight: 400;
}

.introjs-prevbutton {
  display: none;
}

.introjs-nextbutton {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-shadow: unset !important;
  background-color: #df6438 !important;
  padding: 8px 32px !important;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: unset !important;
}

.introjs-tooltipbuttons {
  border-top: unset;
  padding: 0px;
  text-align: right;
  white-space: nowrap;
}

.introjs-helperLayer {
  border-radius: 10px !important;
  box-shadow: #ffffff 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.introjs-skipbutton {
  line-height: unset;
  color: black;
  font-size: 40px;
  font-weight: 400;
}

.introjs-prevbutton {
  display: none;
}

.introjs-nextbutton {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-shadow: unset !important;
  background-color: #df6438 !important;
  padding: 8px 32px !important;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: unset !important;
}

.introjs-tooltipbuttons {
  border-top: unset;
  padding: 0px;
  text-align: right;
  white-space: nowrap;
}

.introjs-helperLayer {
  border-radius: 10px !important;
  box-shadow: #ffffff 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
